import t, { useIntl } from '@jetshop/intl';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import React, { useState, useEffect } from 'react';
import { theme } from './Theme';
import { CTA } from 'r3-lib/dist/r3lib';
import { SVG } from '../assets/SVG';
import { DynamicCategoryRenderer } from './ContentEditor/DynamicCategoryRenderer/DynamicCategoryRenderer';

const ErrorContainer = styled('div')`
  overflow: hidden;
  display: flex;
  position: relative;
  min-height: 500px;

  .container {
    max-width: 500px;
    min-height: 500px;
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    text-align: center;
    ${theme.below.lg} {
      max-width: 80%;
    }

    &.visible {
      opacity: 1;
      transition: opacity 0.5s 0.5s;
    }
    p {
      max-width: 280px;
      margin: 1rem auto 2rem auto;
      text-transform: none;
    }
    .styled-button-wrapper {
      max-width: 200px;
      margin: 0 auto;
    }
  }

  .logo-wrapper {
    max-width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${theme.below.lg} {
      max-width: 80%;
    }

    svg {
      width: 100%;
      height: auto;
      color: black;
      transition: transform 1s, opacity 0.5s 0.5s;
    }

    &.move {
      .icon {
        transform: translateX(-250%);
        opacity: 0;
      }
    }

    &.background {
      opacity: 0.08;
    }
  }
`;

const NotFoundPage = () => {
  const [move, setMove] = useState(false);
  const animationDelay = 800; // milliseconds

  useEffect(() => {
    setTimeout(() => {
      setMove(true);
    }, animationDelay);
  }, []);

  const translate = useIntl();

  const pageNotFoundText = translate('Page not found');
  const sorryText = translate(
    "We're sorry, but the page you're looking for is currently unavailable"
  );

  return (
    <>
      <ErrorContainer>
        <div
          onClick={() => {
            setMove(!move);
          }}
          className={cx('logo-wrapper', 'background')}
        >
          <SVG name={process.env.REACT_APP_LOGO_NAME} />
        </div>
        <div
          onClick={() => {
            setMove(!move);
          }}
          className={cx('logo-wrapper', move && 'move')}
        >
          <SVG name={process.env.REACT_APP_LOGO_NAME} />
        </div>

        <div className={cx('container', move && 'visible')}>
          <h2>{pageNotFoundText}</h2>
          <p>{sorryText}</p>
          <CTA link={'/'} className={'accent'}>
            {t('To Startpage')}
          </CTA>
        </div>
      </ErrorContainer>
      <DynamicCategoryRenderer
        categoryId={process?.env?.REACT_APP_404_CATEGORY_ID}
      />
    </>
  );
};

export default NotFoundPage;
